<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div :class="['top', { active: filtersIsVisible }]">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="title">{{ $t("Filters") }}</div>
                <div class="filters-price-container">
                  <div :class="['title small price', { active: !priceListIsVisible }]"
                    @click="priceListIsVisible = !priceListIsVisible">
                    {{ $t("Price") }}
                  </div>
                  <div :class="['select-container select-container-range', { hidden: !priceListIsVisible }]">
                    <div class="val-container">
                      <div class="val min">
                        <input type="number" v-model="barMinValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                      <div class="divider"></div>
                      <div class="val max">
                        <input type="number" v-model="barMaxValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-for="(filter, filterName) in filters">
                  <div :key="filterName" :class="`filter-${filterName}-container`" v-if="filter.options.length > 1">
                    <div :class="['title small', { active: !filter.visible }]"
                      @click="toggleFilterVisibility(filterName)">
                      {{ formatFilterName(filterName) }}
                    </div>
                    <div :class="['select-container', { hidden: !filter.visible }]">
                      <label class="chekbox-label" v-for="(option, i) in filter.options" :key="i">
                        <div class="chekbox-label-wrapper">
                          <input type="radio" :name="filterName" :value="option"
                            v-model="filters[filterName].selected" />
                          <div class="checkbox"></div>
                          <span class="title">{{ option }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </template>

                <div class="select-container select-container-reset">
                  <div class="button" @click="resetFilters">{{ $t("Reset") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="['products-container', { active: !filtersIsVisible }]">
            <div class="sort-checkbox-list">
              <div class="desc">{{ $t("Sort") }}:</div>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="desc" value="desc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">{{ $t("Highest price first") }}</span>
                </div>
              </label>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="asc" value="asc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">{{ $t("Lowest price first") }}</span>
                </div>
              </label>
            </div>
            <transition name="fade">
              <div class="list products" v-if="productList && productList.length">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true"
                    @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart" />
                </div>
              </div>
            </transition>

          </div>
          <!-- <pagination :per-page="18" v-if="totalProducts" v-model="activePage" :records="totalProducts"
            @paginate="changePage" /> -->
          <CustomPagination v-if="totalProducts" :total-pages="totalPages" :per-page.sync="perPage"
            :current-page.sync="activePage" @update:currentPage="changePage" />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from "../components/ProductCard.vue";
import CustomPagination from '../components/CustomPagination.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem", "currency"],
  components: {
    ProductCard,
    CustomPagination
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.updateFiltersFromQueryParams();
  //   next();
  // },
  data: function () {
    return {
      imgDomain: "",
      filtersIsVisible: true,
      sort: "desc",
      category: "",
      quality: "",
      totalProducts: null,

      totalPages: 1,
      perPage: 16,
      activePage: 1,

      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 9999,
      productList: {},
      typeListIsVisible: true,
      qualityListIsVisible: false,
      priceListIsVisible: true,
      specialCategory: "",
      selectedGame: "",

      isResettingFilters: false,
      isInitialLoad: true,

      filters: {
        types: {
          options: [],
          selected: 'all',
          visible: true,
          apiParam: 'type'
        },
        qualities: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'quality'
        },
        heroes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'hero'
        },
        exterior_names: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'exterior_name'
        },
        classes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'item_class'
        }
      },
    };
  },
  computed: {
    ...mapGetters("app", ["currencySymbol", "currency"]),
  },
  watch: {
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    barMaxValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    sort() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    filters: {
      deep: true,
      handler() {
        if (!this.isResettingFilters && !this.isInitialLoad) {
          this.filterProducts();
        }
      }
    },
    selectedGame() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.resetFilters();
        this.fetchFilterOptions();
      }
    },
    $route() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.fetchActiveDiscount(this.currency);
        this.checkRouteParams();
      }
    },
  },
  mounted() {
    this.checkRouteParams();
    this.fetchFilterOptions();
    this.fetchActiveDiscount(this.currency);
    this.$nextTick(() => {
      this.isInitialLoad = false;
    });
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    updateFiltersFromQueryParams() {
      for (const filterName in this.filters) {
        const filter = this.filters[filterName];
        const queryParamValue = this.$route.query[filter.apiParam];
        if (queryParamValue) {
          if (queryParamValue === 'cases') {
            filter.selected = 'cases';
          } else {
            filter.selected = 'all';
          }
        } else {
          filter.selected = 'all';
        }
      }
    },
    fetchFilterOptions() {
      this.$http.get(`${process.env.VUE_APP_API}items/filter-data?category=${this.selectedGame}`)
        .then((res) => {
          const data = res.data.payload;
          for (const [key, options] of Object.entries(data)) {
            if (this.filters[key]) {
              let newOptions = ['all', ...options.map((option) => option.toLowerCase())];
              if (this.selectedGame === 'rust' && key === 'types') {
                newOptions.splice(1, 0, 'cases');
              }
              this.filters[key].options = newOptions;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching filter options:", error);
        });
    },
    toggleFilterVisibility(filterName) {
      this.filters[filterName].visible = !this.filters[filterName].visible;
    },
    formatFilterName(name) {
      return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    checkRouteParams() {
      if (this.$route.params.type == 'dota2' || this.$route.params.type == 'rust') {
        this.selectedGame = this.$route.params.type;
      }
      // if (this.$route.params.type == "revolution-collection") {
      //   this.specialCategory = this.$route.params.type;
      // } else if (this.$route.params.type && this.$route.params.type != "all") {
      //   this.category = this.$route.params.type;
      //   this.specialCategory = "";
      // } else if (this.$route.params.type && this.$route.params.type === "all") {
      //   this.category = "";
      //   this.specialCategory = "";
      // }
      this.activePage = 1;
      this.updateFiltersFromQueryParams();
      this.filterProducts();
    },
    resetFilters() {
      this.isResettingFilters = true;
      for (const filterName in this.filters) {
        this.filters[filterName].selected = 'all';
      }
      this.barMinValue = 0;
      this.barMaxValue = 9999;
      this.$nextTick(() => {
        this.isResettingFilters = false;
        this.filterProducts();
      });
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let params = new URLSearchParams();
      params.append('category', this.selectedGame);

      for (const [, filter] of Object.entries(this.filters)) {
        if (filter.selected && !filter.selected.startsWith('all')) {
          params.append(filter.apiParam, filter.selected);
        }
      }

      params.append('price_from', this.barMinValue);
      params.append('price_till', this.barMaxValue);
      params.append('sort', this.sort);
      params.append('page', this.activePage);
      params.append('limit', this.perPage);

      this.$http.get(`${process.env.VUE_APP_API}items/list?${params.toString()}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.totalPages = res.data.meta.pages;
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          if (error.response && error.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    changePage: debounce(function (page) {
      this.activePage = page;
      this.filterProducts();
    }, 250),
    addToCart(item, id, item_type) {
      this.$emit("addToCart", item, id, item_type);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list-section {}
</style>